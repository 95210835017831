// LoaderContext.js
import React, { createContext, useContext, useState } from 'react';

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
    const [loaders, setLoaders] = useState({});

    // Set loading spinner state for a specific key (component)
    const setLoadingSpinner = (key, value) => {
        setLoaders((prev) => ({ ...prev, [key]: value }));
    };

    // Access loading spinner state for a specific component
    const isLoading = (key) => loaders[key] || false;

    return (
        <LoaderContext.Provider value={{ setLoadingSpinner, isLoading }}>
            {children}
        </LoaderContext.Provider>
    );
};

export const useLoader = () => useContext(LoaderContext);
