export const serviceSearchRequiredField = "Please enter the location and service."
export const failedToFetchService = "Failed to fetch services."
export const failedToFetchAvailableService = "There was an issue fetching available services."
export const failedToFetchData = 'There was an issue fetching the data.'
export const failedUpdateUserDetail = 'An error occurred while updating user details.'
export const failedToUpdateStatus = 'Error updating status:'
export const failedToSaveChanges = 'Failed to save changes'
export const serviceProviderUpdate = 'Service Provider Details Updated Successfully!'
export const failedToUpdateServiceStatus = "Failed to update service status"
export const UserDetailUpdated = 'User details updated successfully.'
export const ErrorResponse = 'Network response was not ok'
export const failedToUpdateService = "Failed to update service"
export const serviceNameAndDescriptionRequired = "Both Service Name and Service Description are required."
export const failedToAddNewService = "This service is already available."
export const profileUpdated = 'Profile updated successfully.'
export const NameValidation = "Name cannot be empty"
export const failedToUpdateUserDetail = 'Failed to update user details.'
export const failedToUpdateUserProfile = 'An error occurred while updating user profile.'
export const failedToFetchStatistics = "Error fetching statistics:"
export const failedToFetchUserDetails = "Error fetching user details:"
export const messageRecieved = "Thank you for reaching out! We've received your message and will connect with you shortly."
export const validMail = "Please enter a valid email address."
export const failedToSendMail = "Oops, it seems we are currently unable to send your message. Please email us at info@cincologic.com."
export const verifiedOTP = 'OTP verified successfully!'
export const failedToVerifyOTP = 'OTP verification failed. Please try again later.'
export const invalidOTP = 'Invalid OTP, please try again.'
export const invalidPhoneNumber = "Please enter a valid 10-digit phone number."
export const registrationSuccess = 'Registration successful!'
export const registrationFailed = 'Registration failed!'
export const registerUser = "You are not a registered user. Please go to the registration page."
export const failedToCheckUser = "Error checking user:"
export const failedToCheckInformation = "An error occurred while checking your information. Please try again."
export const unknownRole = 'Unknown role'
export const Footermsg = "Please enter a valid phone number. Only numbers and '+' are allowed."
export const numberNotAssociated = "Please enter valid Number."
export const messageValidation = "Please enter valid Message."
export const RatingUpdated = 'Rating updated successfully'
export const FailedToUpdateRating = 'Failed to update rating'
export const ErrorUpdatingRating = 'Error updating rating'
export const failedToDeleteRating = 'Failed to Delete rating'
export const RatingDelete = 'Rating Deleted Successfully'
export const mailNotAssociated = "Please enter valid Email"
export const failedToFindLatLng = 'No results found for the provided lat/lng'
export const failedToFindProviders = "No service providers are available in the selected area. please increase the distance to search again."
export const geocoderFailed = 'Geocoder failed due to: '
export const failedToFindPlace = 'Place not found or no geometry returned'
export const failedToFetchServiceProviders = 'Error fetching service providers:'
export const ratingSubmitted = 'Rating submitted successfully:'
export const BadRequest = 'Network response was not ok'
export const oneRatingValidation = 'You can only submit one rating for each service provider.'
export const PopularServices = 'Popular Services';
export const ServiceDetails = {
    Plumber: "A plumber specializes in installing and repairing water systems, including pipes, faucets, and toilets. They ensure proper drainage and manage plumbing emergencies to keep your home running smoothly.",

    Electrician: "Electricians are trained professionals who install and maintain electrical systems. They handle wiring, lighting, and circuit repairs, ensuring your home is safe and compliant with electrical codes.",

    AirConditionerRepair: "This service focuses on diagnosing and fixing issues with air conditioning units. Technicians ensure your AC operates efficiently, providing comfort during hot weather by addressing problems like leaks or inadequate cooling.",

    TVRepair: "TV repair services involve fixing issues with televisions, including LED and LCD models. Technicians troubleshoot problems such as screen damage or connectivity issues to restore your viewing experience.",

    Painter: "Painters apply coatings to surfaces for aesthetic appeal and protection. They work on both interior and exterior projects, enhancing the look of homes with quality finishes in various colors and textures.",

    PestControl: "Pest control services aim to eliminate unwanted insects and rodents from homes. Professionals assess infestations and use safe methods to protect your living space from pests like termites, ants, and mice.",

    Gardener: "Gardeners cultivate and maintain outdoor spaces. They plant flowers, shrubs, and vegetables while ensuring proper care through watering, pruning, and pest management to keep gardens healthy and vibrant.",

    Mason: "Masons specialize in constructing structures using bricks, stones, or concrete. They create durable features like walls, patios, and walkways while ensuring structural integrity and aesthetic appeal.",

    Welder: "Welders fabricate metal structures by joining materials through welding techniques. This service includes custom metalwork for construction projects or repairs on existing metal items, ensuring strength and durability.",

    Cleaner: "Cleaning services involve thorough cleaning of residential or commercial spaces. Cleaners handle tasks like dusting, vacuuming, and sanitizing to create a clean and healthy environment for occupants.",

    AutomobileMechanic: "Mechanics repair and maintain vehicles to ensure they operate safely. They perform diagnostics, oil changes, brake repairs, and other services to keep cars running efficiently on the road.",

    FridgeRepair: "Fridge repair services address problems with refrigeration units. Technicians diagnose issues like temperature fluctuations or mechanical failures to ensure your fridge keeps food fresh and safe.",

    Carpenter: "Carpenters work with wood to create structures such as furniture, cabinets, or frameworks for buildings. They focus on craftsmanship to deliver functional and aesthetically pleasing wooden products.",

    NewService: "This category encompasses newly introduced services that cater to evolving customer needs. It highlights innovative solutions that enhance convenience and satisfaction in various areas of daily life.",

    Tiffin: "Tiffin services provide home-cooked meals delivered to customers. This service offers a variety of meal options tailored to individual preferences, making it convenient for those seeking nutritious dining solutions."
};

export const AboutCompany = (
    <span>
        Cincologic is a dynamic startup committed to providing world-class software services. We focus on delivering innovative, user-friendly solutions. We have developed{' '}
        <a href="https://www.quickservicefinder.com" target="_blank" rel="noopener noreferrer">
            www.quickservicefinder.com
        </a>, an app that allows users to easily search for nearby service providers based on their needs. Service providers can register with us for free, ensuring they are listed on our platform and accessible to potential customers.
    </span>
);

export const QuickServiceFinderTitles = {
    baseTitle: 'Quick Service Finder',
    sections: {
        register: 'Register',
        services: 'Services',
        serviceProviders: 'Service Providers',
        ratings: 'Ratings',
        profile: 'Profile',
        reviews:'Reviews'
    }
}